<template>
    <div>
        <SelectField
            v-model="modelValue.account_uid"
            name="account_title"
            label="recipient"
            help="help_transfer_investment_account_title"
            :options="accountSelectOptions"
            :required="true"
            :on-search="loadAccountSelectOptions"
        />
        <ToggleSwitch
            v-model="modelValue.keep_original_order"
            name="keep_original_order"
            label="keep_original_order"
            help="help_keep_original_order"
        />
        <TextField
            v-model="modelValue.message"
            name="message"
            label="transaction_message"
            help="help_transaction_message"
        />
    </div>
</template>

<script setup lang="ts">
import { SelectField, TextField, ToggleSwitch } from '@src/components'
import { Account, TransferOrder } from '@src/types'
import { getAccounts } from '@src/utils/api/accounts'
import { Ref, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    modelValue: Ref<TransferOrder>
}>()

const { modelValue } = toRefs(props)

const i18n = useI18n()

const accountSelectOptions = ref<{ label: string; value: string | null }[]>([])

async function loadAccountSelectOptions(search: string) {
    const request = await getAccounts({
        'filter[account_title]': search,
        // 'filter[wallet_status]': WalletStatus.WALLET_STATUS_KYC2,
        'filter[platform_name_exact]': modelValue.value.platform_name,
    })
    accountSelectOptions.value = request.data.accounts.map(
        (account: Account) => {
            const accountType = account.legal_profile_uid
                ? 'legal_profile'
                : 'natural_profile'
            return {
                label: `${account.account_title} - ${i18n.t(accountType)} (${
                    account.account_uid
                })`,
                value: account.account_uid,
            }
        }
    )
}
</script>

<style scoped></style>

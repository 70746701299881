<template>
    <OrdersTable>
        <template #actions>
            <ChangeOrderStatusButton
                v-if="userStore.can('bulk_change_orders_status')"
                v-model:selected="selected"
                @click.stop="() => openBulkChangeOrderStatusModal()"
            />
            <ChangeOrderCampaignButton
                v-if="userStore.can('bulk_change_orders_campaign')"
                v-model:selected="selected"
                @click.stop="() => openBulkChangeOrderCampaignModal()"
            />

            <CreateButton
                v-if="userStore.can('create_order')"
                @click.stop="openCreateOrderSelectUser"
            />
        </template>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.order_uid"
                            name="order_id"
                            :placeholder="$t('order_id')"
                            :disabled="disableFilter('order_uid')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_on"
                            v-model:dateFrom="filtersFields.date_from"
                            v-model:dateTo="filtersFields.date_to"
                            placeholder="created_on"
                            name="filter_created_on"
                            datemode="range"
                            :disabled="disableFilter('created_on')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="platformsSelectOptions"
                            name="platform_name"
                            class="w-full"
                            :placeholder="$t('platform')"
                            :disabled="disableFilter('platform_name_exact')"
                            :on-search="loadPlatformsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.asset_uid"
                            v-model:options="assetSelectOptions"
                            name="asset_uid"
                            class="w-full"
                            :disabled="disableFilter('asset_uid')"
                            :placeholder="$t('asset')"
                            :on-search="loadAssetSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.asset_var_uid"
                            v-model:options="campaignsSelectOptions"
                            name="asset_var_uid"
                            class="w-full"
                            :placeholder="$t('campaign')"
                            :disabled="disableFilter('asset_var_uid')"
                            :on-search="loadCampaignsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.account_title"
                            name="account_title"
                            :placeholder="$t('account_title')"
                            :disabled="disableFilter('account_title')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.status"
                            name="status"
                            :options="mapEnumToArray(OrderStatus)"
                            :reduce="reduceEnumLabel(OrderStatus)"
                            :placeholder="$t('status')"
                            :disabled="disableFilter('status')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.total_amount"
                            name="amount"
                            :placeholder="$t('amount')"
                            :disabled="disableFilter('amount')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.savings_plan"
                            name="savings_plan"
                            :options="[
                                { label: $t('yes'), value: true },
                                { label: $t('no'), value: false },
                            ]"
                            :reduce="reduceEnumLabel(OrderStatus)"
                            :placeholder="$t('savings_plan')"
                            :disabled="disableFilter('savings_plan')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.is_gift"
                            name="is_gift"
                            :options="[
                                { label: $t('yes'), value: true },
                                { label: $t('no'), value: false },
                            ]"
                            :reduce="reduceEnumLabel(OrderStatus)"
                            :placeholder="$t('is_gift')"
                            :disabled="disableFilter('is_gift')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
        <template #menu>
            <ActionsMenu />
        </template>
    </OrdersTable>

    <CreateOrderSelectUserModal
        v-if="createOrderSelectUserModalOpen"
        ref="createOrderSelectUserModal"
        v-model="tmpOrder"
        v-model:open="createOrderSelectUserModalOpen"
        :disabled-fields="createDisabledFields"
        :asset-uid="props.initialFilters?.asset_uid"
        :asset-var-uid="props.initialFilters?.asset_var_uid"
        @submit="createOrder"
        @cancel="closeCreateOrderModal"
    />
    <SetOrderStatusModal
        ref="setOrderStatusModal"
        v-model="selectedOrder"
        v-model:open="setOrderStatusModalOpen"
        :title="statusModalTitle"
        :to-status="changeToStatus"
        @submit="setOrderStatus"
        @cancel="closeSetOrderStatusModal"
    />

    <ModalWrapper v-if="isModalOpen" />
</template>

<script setup lang="ts">
import {
    ModalButtonStyles,
    useActionsMenu,
    useEnum,
    useModal,
    useTable,
} from '@composables/common'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import { OrderStatus } from '@src/enums'
import ChangeOrderCampaignButton from '@src/partials/actions/ChangeOrderCampaignButton.vue'
import ChangeOrderStatusButton from '@src/partials/actions/ChangeOrderStatusButton.vue'
import CopyButton from '@src/partials/actions/CopyButton.vue'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import CreateOrderSelectUserModal from '@src/partials/orders/CreateOrderSelectUserModal.vue'
import OrderBulkCampaignChange from '@src/partials/orders/OrderBulkCampaignChange.vue'
import OrderBulkStatusChange from '@src/partials/orders/OrderBulkStatusChange.vue'
import SetOrderStatusModal from '@src/partials/orders/SetOrderStatusModal.vue'
import {
    Asset,
    Campaign,
    ChangeOrdersCampaignPayload,
    ChangeOrdersStatusPayload,
    CreateOrderPayload,
    Order,
    Platform,
    TableSorting,
} from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import { getCampaign, getCampaigns } from '@src/utils/api/campaigns'
import {
    createOrder as apiCreateOrder,
    exportOrders,
    getOrders,
    updateOrdersCampaign,
    updateOrdersStatus,
} from '@src/utils/api/orders'
import { getPlatforms } from '@src/utils/api/platforms'
import { formatNumericValues, handleEnterPress } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { computed, h, onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])
const platformsSelectOptions = ref<string[]>([])
const campaignsSelectOptions = ref<{ label: string; value: string | null }[]>(
    []
)

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            order_uid?: string | null
            asset_uid?: string | null
            asset_var_uid?: string | null
            created_on?: string | null
            platform_name_exact?: string | null
            account_title?: string | null
            status?: OrderStatus | null
            total_amount?: number | null
            account_uid?: string | null
        } | null
        createInitialValues?: CreateOrderPayload | null
        createDisabledFields?: string[] | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters, createInitialValues, createDisabledFields } =
    toRefs(props)

const { mapEnumToArray, reduceEnumLabel } = useEnum()

const { ModalWrapper, openModal } = useModal()

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const { ActionsMenu } = useActionsMenu(
    {
        id: 'orders-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'export_orders_csv',
            icon: 'fa-cart-shopping',
            color: 'text-primary-accent-600',
            action: exportOrdersCsv,
        },
    ]
)

const createOrderSelectUserModal = ref(false)
const createOrderSelectUserModalOpen = ref(false)
const tmpOrder = ref<CreateOrderPayload>(new CreateOrderPayload())
const selectedOrder = ref<Order>(new Order())

const orders = ref<Order[]>([])
const ordersCount = ref<number>(0)

const setOrderStatusModalOpen = ref(false)
const statusModalTitle = ref<string>('')
const changeToStatus = ref<number>(OrderStatus.CANCELED)
const isModalOpen = ref(false)
const bulkChangeToStatus = ref<number>(-1)
const platforms = ref<Platform[]>([])
const campaignToChange = ref<string | null>(null)

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: OrdersTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadOrders,
    filters: {
        order_uid: null,
        asset_uid: null,
        asset_var_uid: null,
        created_on: null,
        date_from: null,
        date_to: null,
        platform_name: null,
        platform_name_exact: null,
        account_title: null,
        status: null,
        total_amount: null,
        search: null,
        is_gift: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'orders-table',
        label: 'orders',
        help: 'help_orders_table',
        pagination: true,
        data: orders,
        total: ordersCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_on',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'order_uid',
                select: true,
            },
            {
                label: 'id',
                key: 'order_uid',
                url: orderUrl,
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'created_at',
                key: 'created_on',
                sorting: true,
                cellRenderer: (props: { rowData: Order }) => {
                    return moment(props.rowData.created_on).format('DD.MM.yyyy')
                },
            },
            {
                label: 'platform',
                key: 'platform_name',
                sorting: true,
                cellRenderer: shallowRef(PlatformNameCellRenderer),
            },
            {
                label: 'campaign',
                key: 'asset_uid',
                url: campaignUrl,
                sorting: true,
                cellRenderer: (props: { rowData: Order }) => {
                    return h(
                        'div',
                        {
                            class: 'flex items-center',
                        },
                        [
                            h(
                                'span',
                                props.rowData.asset_data?.asset_title as string
                            ),
                            h(CopyButton, {
                                value: props.rowData.asset_data?.asset_title,
                            }),
                        ]
                    )
                },
            },
            {
                label: 'account',
                key: 'account_title',
                url: accountUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'status',
                key: 'status',
                sorting: true,
                cellRenderer: (props: { rowData: Order }) => {
                    return getEnumLabel(OrderStatus, props.rowData.status)
                },
            },
            {
                label: 'amount',
                key: 'total_amount',
                sorting: true,
                cellRenderer: (props: { rowData: Order }) => {
                    return formatNumericValues(
                        props.rowData.amount as number,
                        props.rowData.asset_data?.currency as string
                    )
                },
            },
        ],
        actions: [
            {
                action: handleViewOrder,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_orders'),
            },
            {
                action: handleEditOrder,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_order'),
            },
            {
                action: handleCancelOrder,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: (order: Order) => {
                    return (
                        userStore.can('delete_order') &&
                        order.status !== OrderStatus['CANCELED']
                    )
                },
            },
        ],
    },
})

function orderUrl(order: Order) {
    return '/orders/' + order.order_uid
}

function campaignUrl(order: Order) {
    return '/campaigns/' + order.asset_var_uid
}
function accountUrl(order: Order) {
    return order.legal_profile_id
        ? '/legalProfile/' + order.legal_profile_id
        : '/naturalProfile/' + order.natural_profile_id
}

function handleViewOrder(order: Order): void {
    router.push({
        name: 'order',
        params: { id: order.order_uid },
    })
}

function handleEditOrder(order: Order): void {
    router.push({
        name: 'order.edit',
        params: { id: order.order_uid },
    })
}

const selectedOrders = computed(() => {
    return orders.value.filter(
        (order) => order.order_uid && selected.value.includes(order.order_uid)
    )
})

async function createOrder(next?: string) {
    const createOrderRequest = await apiCreateOrder(tmpOrder.value)
    const { order_uid } = createOrderRequest.data.order

    createOrderSelectUserModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'order.edit',
            params: { id: order_uid },
        })
    } else if (next && next == 'view') {
        router.push({
            name: 'order',
            params: { id: order_uid },
        })
    } else {
        page.value = 1
        limit.value = 10
        await loadOrders()
    }

    tmpOrder.value = tmpOrder.value = new CreateOrderPayload()

    toast.success(i18n.t('toast_success_order_created'))

    closeCreateOrderModal()
}

function openCreateOrderSelectUser(): void {
    if (createInitialValues.value) {
        tmpOrder.value = {
            ...tmpOrder.value,
            ...createInitialValues.value,
        }
    }
    createOrderSelectUserModalOpen.value = true
}

function closeCreateOrderModal() {
    tmpOrder.value = new CreateOrderPayload()
    createOrderSelectUserModalOpen.value = false
}

async function handleCancelOrder(order: Order) {
    selectedOrder.value = { ...order }
    statusModalTitle.value = 'modal_title_cancel_order'
    changeToStatus.value = OrderStatus.CANCELED
    setOrderStatusModalOpen.value = true
}

function openBulkChangeOrderStatusModal() {
    isModalOpen.value = true
    openModal(
        {
            id: 'bulk_change_order_status',
            title: 'modal_title_bulk_change_order_status',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => bulkChangeOrderStatus(),
        },
        () =>
            h(OrderBulkStatusChange, {
                orders: selectedOrders,
                modelValue: bulkChangeToStatus,
            }),
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

function openBulkChangeOrderCampaignModal() {
    isModalOpen.value = true
    openModal(
        {
            id: 'bulk_change_order_campaign',
            title: 'modal_title_bulk_change_order_campaign',
            open: isModalOpen,
            onCancel: () => {
                isModalOpen.value = false
                campaignToChange.value = null
            },
            onSubmit: () => bulkChangeOrderCampaign(),
        },
        () =>
            h(OrderBulkCampaignChange, {
                orders: selectedOrders,
                modelValue: campaignToChange,
            }),
        {
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

function closeSetOrderStatusModal() {
    setOrderStatusModalOpen.value = false
}

async function setOrderStatus(order: Order) {
    if (!order.order_uid) {
        return
    }
    const updateOrdersResponse = await updateOrdersStatus([
        {
            id: order.order_uid as string,
            body: {
                status: OrderStatus.CANCELED,
            },
        },
    ])
    if (updateOrdersResponse.data.success_count) {
        toast.success(i18n.t('toast_success_order_status_changed'))
    }
    setOrderStatusModalOpen.value = false
    await loadOrders()
}

async function bulkChangeOrderStatus() {
    const updateOrdersResponse = await updateOrdersStatus(
        selectedOrders.value.map((order: Order): ChangeOrdersStatusPayload => {
            return {
                id: order.order_uid as string,
                body: {
                    status: bulkChangeToStatus.value,
                },
            }
        })
    )
    if (updateOrdersResponse.data.success_count) {
        toast.success(
            i18n.t('toast_success_orders_status_changed', {
                orders: selectedOrders.value
                    .map((item) => item.order_uid)
                    .join(', '),
            })
        )
    }

    selected.value = []
    isModalOpen.value = false
    await loadOrders()
}

async function bulkChangeOrderCampaign() {
    const response = await updateOrdersCampaign(
        selectedOrders.value.map(
            (order: Order): ChangeOrdersCampaignPayload => {
                return {
                    id: order.order_uid as string,
                    body: {
                        campaign_id: campaignToChange.value as string,
                    },
                }
            }
        )
    )
    if (response.data.success_count) {
        toast.success(
            i18n.t('toast_success_orders_campaign_changed', {
                orders: selectedOrders.value
                    .map((item) => item.order_uid)
                    .join(', '),
            })
        )
    }

    selected.value = []
    isModalOpen.value = false
    campaignToChange.value = null
    await loadOrders()
}

async function loadOrders() {
    const orderRequest = await getOrders(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    orders.value = orderRequest.data.orders
    ordersCount.value = orderRequest.data.orders_count

    const platformsRequest = await getPlatforms()
    platforms.value = platformsRequest.data.platforms
}

async function exportOrdersCsv() {
    await exportOrders(filters.value, sorting.value)
        .catch((error) => {
            throw error
        })
        .then(() => {
            toast.success(i18n.t('toast_success_export_csv'))
        })
}

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        }
    })
}

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function loadCampaignsSelectOptions(search: string) {
    const campaignRequest = await getCampaigns({
        'filter[asset_title]': search,
    })
    campaignsSelectOptions.value = campaignRequest.data.asset_variations.map(
        (campaign: Campaign) => {
            return {
                label: campaign.asset_title as string,
                value: campaign.asset_var_uid as string,
            }
        }
    )
}

async function loadCampaignsSelectOptionsByUID(uid: string) {
    const campaignRequest = await getCampaign(uid)
    const campaign = campaignRequest.data.campaign
    campaignsSelectOptions.value = [
        {
            label: campaign.asset_title as string,
            value: campaign.asset_var_uid as string,
        },
    ]
}

async function loadAssetSelectOptionsByUID(uid: string) {
    const assetRequest = await getAsset(uid)
    const asset = assetRequest.data.asset
    assetSelectOptions.value = [
        {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        },
    ]
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }
        setFilters()
    }
    if (filtersFields.value.asset_uid) {
        await loadAssetSelectOptionsByUID(
            filtersFields.value.asset_uid as string
        )
    }

    if (filtersFields.value.asset_var_uid) {
        await loadCampaignsSelectOptionsByUID(
            filtersFields.value.asset_var_uid as string
        )
    }
    if (filtersFields.value.platform_name_exact) {
        await loadPlatformsSelectOptions(
            filtersFields.value.platform_name_exact
        )
    }
    loadOrders()
})
</script>
<style lang="scss" scoped>
input {
    &:disabled {
        @apply disabled:bg-gray-100;
    }
}
</style>

import { OrderStatus } from '@src/enums'
import {
    AssetsIncentive,
    Broker,
    Campaign,
    EmissionSignature,
} from '@src/types'

export class Order {
    order_uid: string | null = null
    account_uid: string | null = null
    asset_var_uid: string | null = null
    asset_uid: string | null = null
    contract_uid: string | null = null
    asset_data: OrderAsset | null = null
    platform_name: string | null = null
    incentive: AssetsIncentive | null = null
    incentive_uid: string | null = null
    amount: number | null = null
    voucher_amount: number | null = null
    wallet_amount: number | null = null
    agio_disagio: number | null = null
    agio_disagio_type: number | null = null
    agio_disagio_rate: number | null = null
    agio_disagio_title: string | null = null
    agio_disagio_disclaimer: string | null = null
    total_amount: number | null = null
    total_amount_to_pay: number | null = null
    voucher_code: string | null = null
    voucher_rate: number | null = null
    reference_number: string | null = null
    broker: Broker | null = null
    account_title: string | null = null
    investor_consent: boolean | null = null
    status: OrderStatus = OrderStatus.PENDING
    created_on: string | number | Date | null = null
    additional_fields?: AdditionalFields
    installments: string | null = null
    regulatory_requirements: OrderRegulatoryRequirements | null = null
    cancellation_date: string | null = null
    paid_date: string | null = null
    investment_acceptance_date: string | null = null
    is_manual: boolean | null = null
    created_at: string | null = null
    updated_at: string | null = null
    disabled_on: string | null = null
    emission_signatures: EmissionSignature[] = []
    needs_signature = false
    has_signature = false
    regenerate_signatures = false
    attachments: OrderAttachment[] = []
    order_documents: OrderDocument[] = []
    reporting_documents: ReportingDocument[] = []
    certificate: OrderCertificate[] = []
    natural_profile_id: string | null = null
    legal_profile_id: string | null = null
    campaign: Campaign | null = null
    savings_plan: boolean | null = null
    is_gift = false
}

export class OpenBasket {
    id: string | null = null
    stage: string | null = null
    account_id: string | null = null
    natural_profile_id: string | null = null
    platform_name: string | null = null
    created_at: string | null = null
    cancelled_at: string | null = null
    campaign: Campaign | null = null
}

export class OrderAsset {
    asset_title: string | null = null
    company_name: string | null = null
    company_logo: string | null = null
    agio_disagio: number | null = null
    agio_disagio_rate: number | null = null
    agio_disagio_type: number | null = null
    agio_disagio_title: string | null = null
    agio_disagio_disclaimer: string | null = null
    bic: string | null = null
    iban: string | null = null
    currency: string | null = null
    clearing_system: string | null = null
    step_level: number | null = null
    appropriateness_test_minimum_experience_and_knowledge: string | null = null
    appropriateness_test_minimum_experience_investment_services: string | null =
        null
}

export class CreateOrderPayload {
    order_uid: string | null = null
    send_mails = false
    attachments: [] = []
    account_uid: string | null = null
    amount = 0
    asset_var_uid = ''
    incentive_uid = ''
    created_on = ''
    contract_type: string | null = null
}

export interface ChangeOrdersStatusPayload {
    id: string
    body: {
        status: number
    }
}

export interface ChangeOrdersCampaignPayload {
    id: string
    body: {
        campaign_id: string
    }
}

export class AdditionalFields {
    depot_owner?: string | null = null
    depot_number?: string | null = null
    depot_bank?: string | null = null
    depot_bic?: string | null = null
}

export class OrderRegulatoryRequirements {
    regulatory_requirement: number | null = null
    city: string | null = null
    date: Date | null = null
    email_confirm: string | null = null
    first_name_confirm: string | null = null
    last_name_confirm: string | null = null
    place_of_birth_confirm: string | null = null
    date_of_birth_confirm: Date | null = null
    identity_card_passport_number_confirm: string | null = null
    issuing_authority_confirm: string | null = null
    address_confirm: string | null = null
    postal_code_confirm: string | null = null
    city_confirm: string | null = null
    state_confirm: string | null = null
    tax_number: string | null = null
    accept_investment_information = false
    accept_investment_under_1000 = false
    accept_investment_income = false
    accept_investment_financial_assets_over_10000 = false
    accept_investment_limit = false
    accept_inappropriate_investment = false
}
export interface OrderAttachment {
    description: string
    id?: string
    name: string
    type: string
    url: string
    upload_key: string
}

export interface OrderDocument {
    description: string
    id?: string
    name: string
    type: string
    url: string
    upload_key: string
}

export interface ReportingDocument {
    description: string
    id?: string
    name: string
    type: string
    url: string
    upload_key: string
}

export interface OrderCertificate {
    id?: string
    name: string
    type: string
    url: string
    upload_key: string
}

export class TransferOrder {
    account_uid: string | null = null
    keep_original_order = false
    platform_name: string | null = null
    message: string | null = null
}

import { AssetSubStatus } from '@src/enums'
import { Asset, NyalaRegistryExtractItem, TableSorting, Task } from '@src/types'
import { ApiRes, api } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface AssetsResponse {
    success: boolean
    assets_count: number
    assets: Asset[]
}

export interface AssetResponse {
    success: boolean
    asset: Asset
}

export interface AssetSubStatusResponse {
    success: boolean
    sub_status: AssetSubStatus[]
}

export interface AssetExportResponse {
    message: string
    tasks: Task[]
}

export interface AssetTransferToProjectWalletResponse {
    log: string
}

interface UploadNyalaRegistryExtractResponse {
    success: boolean
    registry_extract_data: {
        external_tokenized_asset_id: string
        registry_extract: NyalaRegistryExtractItem[]
    }
}

export const getAssets = (
    filters?: object,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<AssetsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/assets', config)
}

export const createAsset = (asset: Asset): ApiRes<AssetResponse> =>
    api.post('v2/manage/assets', asset)

export const getAsset = (id: string): ApiRes<AssetResponse> =>
    api.get('manage/assets/' + id)

export const deleteAsset = (id: string): ApiRes<string> =>
    api.delete('manage/assets/' + id)

export const deleteAssets = (ids: string[]): ApiRes<string> =>
    api.delete('v2/manage/assets', { data: { assets: ids } })

export const updateAsset = (asset: Asset): ApiRes<AssetResponse> =>
    api.patch('manage/assets/' + asset.asset_uid, asset)

export const exportAssetInvestmentReport = (
    asset: Asset
): ApiRes<AssetExportResponse> =>
    api.post(
        'manage/assets/' + asset.asset_uid + '/export-asset-investment-report',
        { lang: 'en', async: 1 }
    )

export const exportCheckOrdersReport = (
    asset: Asset
): ApiRes<AssetExportResponse> =>
    api.post(
        'manage/assets/' + asset.asset_uid + '/export-check-orders-report',
        { lang: 'en', async: 1 }
    )

export const sendAdHocEmailReminder = (
    asset: Asset
): ApiRes<AssetExportResponse> =>
    api.post(
        'manage/assets/' + asset.asset_uid + '/send-no-iban-reminder-email',
        {}
    )

export const sendNyalaRegistryDocumentMail = (
    asset: Asset
): ApiRes<{ success: boolean }> =>
    api.post(
        'manage/assets/' +
            asset.asset_uid +
            '/send-nyala-registry-document-email',
        {}
    )

export const retrieveNyalaRegistryExtract = (
    asset: Asset
): ApiRes<{ success: boolean }> =>
    api.get(
        'manage/assets/' + asset.asset_uid + '/nyala-registry-extract-retrieve'
    )

export const uploadNyalaRegistryExtract = (
    asset: Asset,
    uploadKey: string
): ApiRes<UploadNyalaRegistryExtractResponse> =>
    api.post(
        'manage/assets/' + asset.asset_uid + '/nyala-registry-extract-upload',
        {
            upload_key: uploadKey,
        }
    )

export const transferToProjectWallet = (
    asset: Asset,
    wallet: string,
    date: string
): ApiRes<AssetTransferToProjectWalletResponse> =>
    api.post('manage/assets/' + asset.asset_uid + '/acceptance', {
        external_wallet_id: wallet,
        orders_created_until: date,
    })

export const getAssetSubStatus = (
    status: string
): ApiRes<AssetSubStatusResponse> =>
    api.get(`v2/manage/assets/get-sub-status?status=${status}`)

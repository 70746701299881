<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-sack-dollar" />&nbsp;{{ asset.asset_title }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    asset.asset_uid &&
                    $router.options.history.state.back !==
                        `/assets/${asset.asset_uid}`
                "
                icon="fa-eye"
                title="asset"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/assets'"
                icon="fa-list"
                title="assets"
                @click="toAssets"
            />
            <SaveButton @click="saveAsset" />
        </template>

        <!-- asset details -->
        <AccordionBasic
            title="asset_details"
            class="mb-2"
            :open="true"
        >
            <TextField
                v-model="asset.asset_title"
                name="asset_title"
                label="asset_title"
                help="help_asset_title"
                :required="true"
            />

            <SelectField
                v-model="asset.platform_name"
                v-model:options="platformOptions"
                name="platform_name"
                label="platform_name"
                help="help_platform_name"
                :required="true"
                :on-search="loadPlatformNameOptions"
            />

            <SelectField
                v-model="asset.issuer_id"
                v-model:options="issuerOptions"
                name="issuer_id"
                label="issuer"
                help="help_issuer"
                :required="true"
                :on-search="loadIssuerOptions"
            />

            <SelectField
                v-model="asset.category"
                name="asset_category"
                label="asset_category"
                help="help_asset_category"
                :options="mapEnumToArray(AssetCategory)"
                :reduce="reduceEnumValue"
                :required="true"
            />

            <SelectField
                v-model="asset.status"
                name="status"
                label="status"
                help="help_asset_status"
                :options="mapEnumToArray(AssetStatus)"
                :reduce="reduceEnumValue"
                :required="true"
                :clearable="false"
                :class="'asset-status'"
                @update:model-value="retrieveAssetSubStatus"
            />

            <SelectField
                v-model="asset.sub_status"
                :disabled="
                    asset.status === AssetStatus.ACTIVE ||
                    asset.status === AssetStatus.INACTIVE
                "
                name="sub_status"
                label="sub_status"
                help="help_asset_sub_status"
                :options="mapEnumToArray(assetSubStatuses)"
                :reduce="reduceEnumValue"
                :required="false"
            />
        </AccordionBasic>

        <!-- asset location -->
        <AccordionBasic
            title="asset_location"
            class="mb-2"
        >
            <TextField
                v-model="asset.asset_location_city"
                name="asset_location_city"
                label="city"
                help="help_asset_location_city"
            />
            <TextField
                v-model="asset.asset_location_state"
                name="asset_location_state"
                label="state"
                help="help_asset_location_state"
            />

            <SelectField
                v-model="asset.asset_location_country"
                name="asset_location_country"
                label="country"
                help="help_asset_location_country"
                :options="countries"
                :required="true"
            />
        </AccordionBasic>

        <!-- asset funding limits -->
        <AccordionBasic
            title="asset_funding_limits"
            class="mb-2"
        >
            <NumberField
                v-model="asset.funding_min"
                name="asset_funding_min"
                label="asset_funding_min"
                help="help_asset_funding_min"
                :required="true"
            />
            <NumberField
                v-model="asset.funding_max"
                name="asset_funding_max"
                label="asset_funding_max"
                help="help_asset_funding_max"
                :required="true"
            />
            <NumberField
                v-model="asset.investment_min"
                name="asset_investment_min"
                label="asset_investment_min"
                help="help_asset_investment_min"
                :required="true"
            />
            <NumberField
                v-model="asset.investment_max"
                name="asset_investment_max"
                label="asset_investment_max"
                help="help_asset_investment_max"
                :required="true"
            />
            <NumberField
                v-model="asset.step_level"
                name="asset_step_level"
                label="asset_step_level"
                help="help_asset_step_level"
                :required="true"
            />
            <SelectField
                v-model="asset.currency"
                name="asset_currency"
                label="currency"
                help="help_asset_currency"
                :options="currencyOptions"
                :required="true"
            />
        </AccordionBasic>

        <!-- installment investment -->
        <AccordionBasic
            title="asset_installment_investment"
            class="mb-2"
        >
            <ToggleSwitch
                v-model="asset.installment_investment"
                name="asset_installment_investment"
                label="asset_installment_investment"
            />

            <template v-if="asset.installment_investment">
                <h3 class="mt-4 text-base font-bold text-gray-800">
                    {{ $t('asset_initial_payment') }}
                </h3>
                <div class="lg:flex lg:gap-4">
                    <NumberField
                        v-model="asset.minimum_initial_payment"
                        name="asset_minimum_initial_payment"
                        label="asset_minimum_initial_payment"
                        help="help_asset_minimum_initial_payment"
                        class="w-full lg:w-1/3"
                    />
                    <NumberField
                        v-model="asset.maximum_initial_payment"
                        name="asset_maximum_initial_payment"
                        label="asset_maximum_initial_payment"
                        help="help_asset_maximum_initial_payment"
                        class="w-full lg:w-1/3"
                    />
                    <NumberField
                        v-model="asset.initial_payment_step"
                        name="asset_initial_payment_step"
                        label="asset_initial_payment_step"
                        help="help_asset_initial_payment_step"
                        class="w-full lg:w-1/3"
                    />
                </div>
                <h3 class="mt-4 text-base font-bold text-gray-800">
                    {{ $t('asset_installments') }}
                </h3>
                <div class="lg:flex lg:gap-4">
                    <NumberField
                        v-model="asset.minimum_installment"
                        name="asset_minimum_installment"
                        label="asset_minimum_installment"
                        help="asset_minimum_installment"
                        class="w-full lg:w-1/3"
                    />
                    <NumberField
                        v-model="asset.maximum_installment"
                        name="asset_maximum_installment"
                        label="asset_maximum_installment"
                        help="help_asset_maximum_installment"
                        class="w-full lg:w-1/3"
                    />
                    <NumberField
                        v-model="asset.installment_step"
                        name="asset_installment_step"
                        label="asset_installment_step"
                        help="help_asset_installment_step"
                        class="w-full lg:w-1/3"
                    />
                </div>
                <h3 class="mt-4 text-base font-bold text-gray-800">
                    {{ $t('asset_monthly_payments') }}
                </h3>
                <div class="lg:flex lg:gap-4">
                    <NumberField
                        v-model="asset.minimum_monthly_rates"
                        name="asset_minimum_monthly_rates"
                        label="asset_minimum_monthly_rates"
                        help="help_asset_minimum_monthly_rates"
                        class="w-full lg:w-1/4"
                    />
                    <NumberField
                        v-model="asset.maximum_monthly_rates"
                        name="asset_maximum_monthly_rates"
                        label="asset_maximum_monthly_rates"
                        help="help_asset_maximum_monthly_rates"
                        class="w-full lg:w-1/4"
                    />
                    <NumberField
                        v-model="asset.minimum_yearly_rates"
                        name="asset_minimum_yearly_rates"
                        label="asset_minimum_yearly_rates"
                        help="help_asset_minimum_yearly_rates"
                        class="w-full lg:w-1/4"
                    />
                    <NumberField
                        v-model="asset.maximum_yearly_rates"
                        name="asset_maximum_yearly_rates"
                        label="asset_maximum_yearly_rates"
                        help="help_asset_maximum_yearly_rates"
                        class="w-full lg:w-1/4"
                    />
                </div>
            </template>
        </AccordionBasic>

        <!-- manual funding totals -->
        <AccordionBasic
            v-if="userStore.can('manage_manual_funding_totals')"
            title="manual_investments"
            help="help_manual_investments"
            class="mb-2"
        >
            <ToggleSwitch
                v-model="asset.manual_funding_totals"
                name="asset_manual_funding_totals"
                label="asset_manual_funding_totals"
                help="help_asset_manual_funding_totals"
            />
            <div class="lg:flex lg:gap-4">
                <NumberField
                    v-model="asset.base_funding_count"
                    name="asset_base_funding_count"
                    label="asset_base_funding_count"
                    help="help_asset_base_funding_count"
                    class="w-full lg:w-1/2"
                />
                <NumberField
                    v-model="asset.base_funding_total"
                    name="asset_base_funding_total"
                    label="asset_base_funding_total"
                    help="help_asset_base_funding_total"
                    class="w-full lg:w-1/2"
                />
            </div>
        </AccordionBasic>

        <!-- automatic payments -->
        <AccordionBasic
            title="automatic_payments"
            class="mb-2"
        >
            <ToggleSwitch
                v-model="asset.deactivate_payment_account"
                name="asset_deactivate_payment_account"
                label="asset_deactivate_payment_account"
            />
            <div class="mt-2 text-xs">
                {{ $t('help_asset_deactivate_payment_account') }}
            </div>
        </AccordionBasic>

        <!-- clearing systems -->
        <AccordionBasic
            title="clearing_systems"
            class="mb-2"
        >
            <SelectField
                v-model="asset.clearing_system"
                name="clearing_system"
                label="clearing_system"
                help="help_clearing_system"
                :options="[
                    {
                        label: $t('clearing_system_ci'),
                        value: 'CI',
                    },
                    {
                        label: $t('clearing_system_cf'),
                        value: 'CF',
                    },
                    {
                        label: $t('clearing_system_emission'),
                        value: 'EMISSION',
                    },
                ]"
                :required="true"
            />
        </AccordionBasic>

        <!-- company details -->
        <AccordionBasic
            title="company_details"
            class="mb-2"
        >
            <TextField
                v-model="asset.iban"
                :mask="ibanMask"
                name="company_iban"
                label="iban"
                help="help_company_iban"
            />

            <TextField
                v-model="asset.bic"
                name="company_bic"
                label="bic"
                help="help_company_bic"
            />
        </AccordionBasic>

        <!-- contract details -->
        <AccordionBasic
            title="contract_details"
            class="mb-2"
        >
            <TextField
                v-model="asset.contract_range"
                name="contract_range"
                label="contract_range"
                help="help_contract_range"
            />

            <NumberField
                v-model="asset.interest_rate"
                name="interest_rate"
                label="interest_rate"
                help="help_interest_rate"
            />
        </AccordionBasic>

        <!-- signatures -->
        <AccordionBasic
            title="signatures"
            class="mb-2"
        >
            <h3 class="mb-1 block text-sm font-medium">
                {{ $t('signature_types') }}
            </h3>
            <ToggleSwitch
                v-model="asset.signature_manual"
                name="signature_type_manual"
                label="signature_type_manual"
            />
            <ToggleSwitch
                v-model="asset.signature_sproof"
                name="signature_type_sproof"
                label="signature_type_sproof"
                help="help_asset_signature_types"
            />
            <div v-if="asset.signature_sproof">
                <h3 class="mb-1 mt-4 block text-sm font-medium">
                    {{ $t('digital_signature_types') }}
                </h3>
                <ToggleSwitch
                    v-model="asset.require_qualified_signature"
                    name="require_qualified_signature"
                    label="require_qualified_signature"
                    help="help_require_qualified_signature"
                />
                <ToggleSwitch
                    v-model="asset.require_advanced_qualified_signature"
                    name="require_advanced_qualified_signature"
                    label="require_advanced_qualified_signature"
                    help="help_require_advanced_qualified_signature"
                />
            </div>

            <hr class="mt-4" />

            <EditorField
                v-model="asset.signature_legal_notice"
                name="asset_signature_legal_notice"
                label="asset_signature_legal_notice"
                help="help_asset_signature_legal_notice"
                :required="false"
            />

            <hr class="mt-4" />

            <div class="mt-4 mb-2">
                <label
                    class="mb-1 block text-sm font-medium"
                    for="name"
                    >{{ $t('asset_signature_document') }}</label
                >
            </div>
            <div class="mb-2 flex flex-row gap-2">
                <FileUploadButton
                    title="asset_signature_document"
                    accept=".pdf"
                    mode="private"
                    :file-name="asset?.emission_signature_document?.name"
                    :url="asset?.emission_signature_document?.url"
                    @submit="onEmissionSignatureDocumentUpload"
                    @delete="onEmissionSignatureDocumentDelete"
                />
            </div>

            <TextField
                v-if="asset.emission_signature_document"
                v-model="asset.emission_signature_document.name"
                name="asset_signature_document_name"
                label="asset_signature_document_name"
                help="help_asset_signature_document_name"
                :required="false"
            />

            <TextField
                v-if="asset.emission_signature_document"
                v-model="asset.emission_signature_document.description"
                name="asset_signature_document_description"
                label="asset_signature_document_description"
                help="help_asset_signature_document_description"
                :rows="3"
                :required="false"
            />
        </AccordionBasic>
        <AccordionBasic
            title="payment_methods"
            class="mb-2"
        >
            <ToggleSwitch
                v-model="asset.payment_methods.credit_card"
                name="credit_card"
                label="credit_card"
            />
            <ToggleSwitch
                v-model="asset.payment_methods.wire_transfer"
                name="wire_transfer"
                label="wire_transfer"
            />
        </AccordionBasic>
    </PageWrapper>
</template>
<script setup lang="ts">
import { useMask } from '@composables/common'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import {
    AccordionBasic,
    EditorField,
    NumberField,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import { useEnum } from '@src/composables/common'
import { AssetCategory, AssetStatus, AssetSubStatus } from '@src/enums'
import BackButton from '@src/partials/actions/BackButton.vue'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import {
    Asset,
    EmissionSignatureDocument,
    Issuer,
    PaymentMethods,
    Platform,
} from '@src/types'
import { getAsset, getAssetSubStatus, updateAsset } from '@src/utils/api/assets'
import { getCurrencies } from '@src/utils/api/currencies'
import { getPlatforms } from '@src/utils/api/platforms'
import { UploadFileResponse } from '@src/utils/api/s3'
import { useCountryStore } from '@src/utils/stores/countries'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { getIssuers } from '@utils/api/issuers'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const { mapEnumToArray, reduceEnumValue } = useEnum()
const { ibanMask } = useMask()
const currencyOptions = ref<string[]>([])
const countryStore = useCountryStore()
const countries = ref<{ label: string; value: string | null }[]>([])
const userStore = useUserStore()

const asset = ref<Asset>(new Asset())

const platformOptions = ref<{ label: string; value: string }[]>([])
const issuerOptions = ref<{ label: string; value: string }[]>([])
const assetSubStatuses = ref<AssetSubStatus[]>([])

function goToDetails(): void {
    router.push({ name: 'asset', params: { id: asset.value.asset_uid } })
}

function toAssets(): void {
    router.push({ name: 'assets' })
}

async function saveAsset() {
    await updateAsset(asset.value)
    toast.success(
        i18n.t('toast_success_asset_saved', { asset: asset.value.asset_title })
    )
}

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return {
                label: platform.platform_name as string,
                value: platform.platform_name as string,
            }
        }
    )
}

async function loadIssuerOptions(search: string) {
    const request = await getIssuers({
        'filter[name]': search,
        'filter[platform_name]': asset.value.platform_name,
    })
    issuerOptions.value = request.data.issuers.map((issuer: Issuer) => {
        return {
            value: issuer.id as string,
            label: issuer.name + ' (' + issuer.platform_name + ')',
        }
    })
}

onMounted(async () => {
    if (route.params.id) {
        const assetRequest = await getAsset(route.params.id as string)
        asset.value = assetRequest.data.asset
        if (asset.value.payment_methods === null) {
            asset.value.payment_methods = new PaymentMethods()
        }

        if (asset.value.platform_name !== null) {
            platformOptions.value = [
                {
                    value: asset.value.platform_name as string,
                    label: asset.value.platform_name as string,
                },
            ]
        }
        if (
            asset.value.issuer?.id !== null &&
            asset.value.issuer?.name !== null
        ) {
            issuerOptions.value = [
                {
                    value: asset.value.issuer?.id as string,
                    label:
                        asset.value.issuer?.name +
                        ' (' +
                        asset.value.issuer?.platform_name +
                        ')',
                },
            ]
        }
    }

    loadData()

    const currenciesRequest = await getCurrencies()
    await retrieveAssetSubStatus()
    currencyOptions.value = currenciesRequest.data.currencies
})

function onEmissionSignatureDocumentUpload(
    response: UploadFileResponse,
    fileName: string
) {
    let oldDescription = null

    if (asset.value.emission_signature_document) {
        oldDescription = asset.value.emission_signature_document.description
    }

    asset.value.emission_signature_document = new EmissionSignatureDocument()

    asset.value.emission_signature_document.upload_key = response.key
    asset.value.emission_signature_document.url = response.temp_storage_url
    asset.value.emission_signature_document.name = fileName
    asset.value.emission_signature_document.description = oldDescription
}

function onEmissionSignatureDocumentDelete(): void {
    asset.value.emission_signature_document = new EmissionSignatureDocument()
}

async function retrieveAssetSubStatus() {
    if (asset.value.status) {
        assetSubStatuses.value = []
        const assetSubStatusRequest = await getAssetSubStatus(
            asset.value.status
        )
        if (assetSubStatusRequest.data.success) {
            assetSubStatuses.value = assetSubStatusRequest.data.sub_status ?? {}
            if (
                assetSubStatuses.value === null ||
                Object.values(assetSubStatuses.value).indexOf(
                    asset.value.sub_status
                ) === -1
            ) {
                asset.value.sub_status = null
            }
        }
    }
}

async function loadData() {
    countries.value = await countryStore.loadAndSortCountriesOptionsAsync()
}
watch(i18n.locale, async () => {
    loadData()
})
</script>
<style lang="scss">
div.asset-status {
    button.vs__clear {
        display: none; // @TODO: Vue3 SelectField Component :clearable prop not working!
    }
}
</style>

import {
    ChangeOrdersCampaignPayload,
    ChangeOrdersStatusPayload,
    CreateOrderPayload,
    InterestPaymentTransaction,
    OpenBasket,
    Order,
    TableSorting,
    TransferOrder,
} from '@src/types'
import { ObjectWithStringKeysAndStringValue } from '@src/types/ObjectWithStringKeysAndStringValue'
import { ApiRes, api } from '@src/utils/api/client'
import { AxiosRequestConfig } from 'axios'

export interface OrdersResponse {
    success: string
    orders: Order[]
    orders_count: number
}

export interface OpenBasketsResponse {
    success: string
    baskets: OpenBasket[]
    baskets_count: number
}

export interface ChangeOrdersStatusResponse {
    error_count: number
    message: string
    success_count: number
}

export interface ChangeOrdersCampaignResponse {
    error_count: number
    message: string
    success_count: number
}

export interface OrderResponse {
    success: string
    order: Order
}

export interface ResendMailResponse {
    message: string
}

export interface OrdersDownloadResponse {
    downloadUrl?: string
}

export interface ChangeOrderStatusDatePayload {
    status: number | null
    date: Date | null
}

export interface InterestPaymentTransactionsRequest {
    success: boolean
    interest_payment_transactions: InterestPaymentTransaction[]
    interest_payment_transactions_count: number
}

export const getOrders = (
    filters?: Record<string, unknown>,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<OrdersResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/orders', config)
}

export const getOpenBasket = (
    filters?: Record<string, unknown>,
    sorting?: TableSorting[],
    page?: number,
    limit?: number
): ApiRes<OpenBasketsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('manage/baskets', config)
}

export const getOrder = (id: string): ApiRes<OrderResponse> =>
    api.get('manage/orders/' + id)

export const createOrder = (order: CreateOrderPayload): ApiRes<OrderResponse> =>
    api.post('v2/manage/orders', order)

export const updateOrder = (order: Order): ApiRes<OrderResponse> =>
    api.patch('v2/manage/orders/' + order.order_uid, order)

export const updateOrdersStatus = (
    orders: ChangeOrdersStatusPayload[]
): ApiRes<ChangeOrdersStatusResponse> => api.patch('v2/manage/orders/', orders)

export const updateOrdersCampaign = (
    orders: ChangeOrdersCampaignPayload[]
): ApiRes<ChangeOrdersCampaignResponse> => api.patch('manage/orders/', orders)

export const deleteOrders = (orders: string[]): ApiRes<string> =>
    api.delete('manage/orders', { data: { orders: orders } })

export const deleteOrder = (id: string): ApiRes<string> =>
    api.delete('manage/orders/' + id)

export const exportOrders = (
    filters?: Record<string, unknown>,
    sorting?: TableSorting[],
    page?: number,
    limit?: number
): ApiRes<OrdersDownloadResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    config.params['async'] = 1

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/orders/csv', config)
}

export const exportOpenBaskets = (
    filters?: Record<string, unknown>,
    sorting?: TableSorting[],
    page?: number,
    limit?: number
): ApiRes<OrdersDownloadResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page === 'number' && typeof limit === 'number') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    config.params['async'] = 1

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('manage/baskets/csv', config)
}

export const changeOrderStatusDate = (
    id: string,
    payload: ChangeOrderStatusDatePayload
): ApiRes<OrderResponse> =>
    api.put(`v2/manage/orders/${id}/change-status-date/`, {
        status: payload.status,
        date: payload.date,
    })

export const cancelOrder = (id: string): ApiRes<OrderResponse> =>
    api.put(`v2/manage/orders/${id}/cancel/`)

export const resendEmail = (
    id: string,
    type: string
): ApiRes<ResendMailResponse> =>
    api.post(`v2/manage/orders/${id}/resend-mail`, {
        type: type,
    })

export const getOrderInterestPaymentTransactions = (
    id: string,
    filters?: ObjectWithStringKeysAndStringValue,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<InterestPaymentTransactionsRequest> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get(`manage/orders/${id}/interest_payment_transactions`, config)
}

export const transferOrder = (
    id: string,
    payload: TransferOrder
): ApiRes<OrderResponse> =>
    api.post(`v2/manage/orders/${id}/transfer-order/`, {
        account_uid: payload.account_uid,
        keep_original_order: payload.keep_original_order,
        platform_name: payload.platform_name,
        message: payload.message,
    })

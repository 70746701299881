<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-sack-dollar" />&nbsp;{{ asset.asset_title }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/assets'"
                icon="fa-list"
                title="assets"
                @click="toAssets"
            />
            <EditButton
                v-if="userStore.can('update_asset')"
                title="edit"
                @click="editAsset"
            />
            <DeleteButton
                v-if="userStore.can('delete_asset')"
                @click="openDeleteModal"
            />
            <ActionsMenu
                v-if="
                    userStore.can('view_assets') ||
                    userStore.can('transfer_money') ||
                    userStore.can('trigger_ad_hoc_iban_reminder') ||
                    userStore.can('upload_nyala_registry_extract') ||
                    userStore.can('send_nyala_registry_document_email')
                "
            />
        </template>

        <template #modals>
            <DeleteItemModal
                id="delete-asset-modal"
                v-model="asset"
                v-model:open="deleteModalOpen"
                title="modal_title_delete_asset"
                name-key="asset_title"
                id-key="asset_uid"
                @submit="deleteAsset"
            />
        </template>

        <SummaryWrapper>
            <AssetSummary v-if="assetDetailsItems" />

            <template
                v-if="
                    userStore.is('developer') ||
                    asset.contract ||
                    asset.voucher_contract ||
                    issuer
                "
                #sidebar
            >
                <ContractSummary v-if="asset.contract" />
                <VoucherContractSummary v-if="asset.voucher_contract" />
                <IssuerSummary v-if="issuer" />
                <PlatformSummary v-if="userStore.is('developer')" />
            </template>
        </SummaryWrapper>
        <CampaignsTable
            v-if="asset.asset_uid"
            :initial-filters="{
                asset_uid: asset.asset_uid,
            }"
            :create-initial-values="campaignsTableCreateInitialValues"
            :create-disabled-fields="['asset_uid']"
        />
        <OrdersTable
            v-if="asset.asset_uid"
            :initial-filters="{
                asset_uid: asset.asset_uid,
            }"
        />
        <ContractsTable
            v-if="asset.asset_title"
            :initial-filters="{
                asset_uid: asset.asset_uid,
                asset_title: asset.asset_title,
            }"
        />
        <ContractDocumentsTable>
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_asset')"
                    v-model:selected="selectedContractDocuments"
                    @click.stop="handleDeleteAssetContractDocuments"
                />
                <CreateButton
                    v-if="userStore.can('update_asset')"
                    aria-controls="asset-contract-documents-modals"
                    @click.stop="handleUpdateOrCreateAssetContractDocument"
                />
            </template>
        </ContractDocumentsTable>
        <ReportingDocumentsTable>
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_asset')"
                    v-model:selected="selectedReportingDocuments"
                    @click.stop="handleDeleteAssetReportingDocuments"
                />
                <CreateButton
                    v-if="userStore.can('update_asset')"
                    aria-controls="asset-reporting-documents-modals"
                    @click.stop="handleUpdateOrCreateAssetReportingDocument"
                />
            </template>
        </ReportingDocumentsTable>
        <ConsumerInformationDocumentsTable>
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_asset')"
                    v-model:selected="selectedConsumerInformationDocuments"
                    @click.stop="handleDeleteAssetConsumerInformationDocuments"
                />
                <CreateButton
                    v-if="userStore.can('update_asset')"
                    aria-controls="asset-consumer-information-documents-modals"
                    @click.stop="
                        handleUpdateOrCreateAssetConsumerInformationDocument
                    "
                />
            </template>
        </ConsumerInformationDocumentsTable>
        <TransferToProjectWalletModal
            v-model:open="transferToProjectWalletModalOpen"
            @submit="transferToProjectWallet"
        />
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>

<script setup lang="ts">
import { useAssetDocumentsTable } from '@composables/assets/useAssetDocumentsTable'
import {
    ModalButtonStyles,
    useActionsMenu,
    useModal,
} from '@composables/common'
import UploadNyalaRegistryExtract from '@partials/assets/UploadNyalaRegistryExtract.vue'
import CampaignsTable from '@partials/campaigns/CampaignsTable.vue'
import OrdersTable from '@partials/orders/OrdersTable.vue'
import { DeleteItemModal, SummaryWrapper } from '@src/components'
import { useEnum, useSummary } from '@src/composables/common'
import { useContractSummary } from '@src/composables/summaries/useContractSummary'
import { useIssuerSummary } from '@src/composables/summaries/useIssuerSummary'
import { usePlatformSummary } from '@src/composables/summaries/usePlatformSummary'
import {
    AssetCategory,
    AssetDocumentType,
    AssetStatus,
    AssetSubStatus,
} from '@src/enums'
import BackButton from '@src/partials/actions/BackButton.vue'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import DeleteButton from '@src/partials/actions/DeleteButton.vue'
import EditButton from '@src/partials/actions/EditButton.vue'
import TransferToProjectWalletModal from '@src/partials/assets/TransferToProjectWalletModal.vue'
import ContractsTable from '@src/partials/contracts/ContractsTable.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import {
    Asset,
    AssetDocument,
    Campaign,
    Contract,
    Issuer,
    PaymentMethods,
    Platform,
} from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import {
    deleteAsset as apiDeleteAsset,
    exportAssetInvestmentReport as apiExportAssetInvestmentReport,
    exportCheckOrdersReport as apiExportCheckOrdersReport,
    sendAdHocEmailReminder as apiSendAdHocEmailReminder,
    transferToProjectWallet as apiTransferToProjectWallet,
    uploadNyalaRegistryExtract as apiUploadNyalaRegistryExtract,
    getAsset,
    retrieveNyalaRegistryExtract,
    sendNyalaRegistryDocumentMail,
    updateAsset,
} from '@src/utils/api/assets'
import { getPlatform } from '@src/utils/api/platforms'
import { UploadFileResponse } from '@src/utils/api/s3'
import { formatNumericValues } from '@src/utils/helpers'
import { useCountryStore } from '@src/utils/stores/countries'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { computedAsync } from '@vueuse/core'
import moment from 'moment'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const { ModalWrapper, openModal } = useModal()
const isModalOpen = ref(false)
const userStore = useUserStore()
const countryStore = useCountryStore()
const { getEnumLabel } = useEnum()

const asset = ref<Asset>(new Asset())
const contract = ref<Contract>(new Contract())
const issuer = ref<Issuer>(new Issuer())
const voucherContract = ref<Contract>(new Contract())
const contracts = ref<Contract[]>([])
const contractDocuments = ref<AssetDocument[]>([])
const reportingDocuments = ref<AssetDocument[]>([])
const consumerInformationDocuments = ref<AssetDocument[]>([])
const platform = ref<Platform>(new Platform())

const deleteModalOpen = ref<boolean>(false)
const transferToProjectWalletModalOpen = ref<boolean>(false)

const { ActionsMenu } = useActionsMenu(
    {
        id: 'asset-actions-menu',
        align: 'right',
    },
    [
        {
            label: 'export_asset_investment_report',
            icon: 'fa-sack-dollar',
            color: 'text-primary-accent-600',
            action: exportAssetInvestmentReport,
            condition: () => userStore.can('view_assets'),
        },
        {
            label: 'transfer_to_project_wallet',
            icon: 'fa-money-bill-transfer',
            color: 'text-primary-accent-600',
            action: openTransferToProjectWallet,
            condition: () => userStore.can('transfer_money'),
        },
        {
            label: 'send_ad_hoc_email_reminder',
            icon: 'fa-paper-plane',
            color: 'text-blue-600',
            action: sendAdHocEmailReminder,
            condition: () => userStore.can('trigger_ad_hoc_iban_reminder'),
        },
        {
            label: 'export_check_orders_report',
            icon: 'fa-list-check',
            color: 'text-info-500',
            action: exportCheckOrdersReport,
            condition: () => userStore.can('view_assets'),
        },
        {
            label: 'retrieve_nyala_registry_extract',
            icon: 'fa-gear',
            color: 'text-blue-600',
            action: handleRetrieveNyalaRegistryExtract,
            condition: () =>
                asset.value.has_nyala_project &&
                userStore.can('upload_nyala_registry_extract'),
        },
        {
            label: 'upload_nyala_registry_extract',
            icon: 'fa-paper-plane',
            color: 'text-blue-600',
            action: handleUploadNyalaRegistryExtract,
            condition: () =>
                asset.value.has_nyala_project &&
                userStore.can('upload_nyala_registry_extract'),
        },
        {
            label: 'send_nyala_registry_document_email',
            icon: 'fa-paper-plane',
            color: 'text-blue-600',
            action: handleSendNyalaRegistryDocumentMail,
            condition: () =>
                asset.value.has_nyala_project &&
                userStore.can('send_nyala_registry_document_email'),
        },
    ]
)

const campaignsTableCreateInitialValues = computed(() => {
    return {
        ...new Campaign(),
        ...{
            asset_uid: asset.value.asset_uid,
            platform_name: asset.value.platform_name,
        },
    }
})

const { ContractSummary } = useContractSummary(contract)
const { IssuerSummary } = useIssuerSummary(issuer)

const { ContractSummary: VoucherContractSummary } = useContractSummary(
    voucherContract,
    {
        title: 'voucher_contract',
    }
)

const { PlatformSummary } = usePlatformSummary(platform)

const assetDetailsItems = computedAsync<SummaryItem[]>(async () => {
    return [
        {
            label: i18n.t('id'),
            value: asset.value.asset_uid,
            copy: true,
        },
        {
            label: i18n.t('asset_name'),
            value: asset.value.asset_title,
            copy: true,
        },
        {
            label: i18n.t('Status'),
            value: getEnumLabel(AssetStatus, asset.value.status, true),
        },
        {
            label: i18n.t('Sub Status'),
            value: getEnumLabel(AssetSubStatus, asset.value.sub_status, true),
        },
        {
            label: i18n.t('city'),
            value: asset.value.asset_location_city,
            copy: true,
        },
        {
            label: i18n.t('asset_location_state'),
            value: asset.value.asset_location_state,
            copy: true,
        },
        {
            label: i18n.t('asset_location_country'),
            value: await countryStore.translate(
                asset.value.asset_location_country as string
            ),
            copy: true,
        },
        { label: i18n.t('currency'), value: asset.value.currency },
        {
            label: i18n.t('asset_category'),
            value: getEnumLabel(AssetCategory, 1, true),
        },
        {
            label: i18n.t('clearing_system'),
            value: asset.value.clearing_system,
        },
        { label: i18n.t('interest_rate'), value: asset.value.interest_rate },
        { label: i18n.t('contract_range'), value: asset.value.contract_range },
        {
            label: i18n.t('asset_funding_min'),
            value: formatNumericValues(asset.value.funding_min as number),
        },
        {
            label: i18n.t('asset_funding_max'),
            value: formatNumericValues(asset.value.funding_max as number),
        },
        {
            label: i18n.t('asset_base_funding_count'),
            value: formatNumericValues(
                asset.value.base_funding_count as number
            ),
            condition: !!asset.value.base_funding_count,
        },
        {
            label: i18n.t('asset_base_funding_total'),
            value: formatNumericValues(
                asset.value.base_funding_total as number
            ),
            condition: !!asset.value.base_funding_total,
        },
        {
            label: i18n.t('asset_funding_count'),
            value: formatNumericValues(asset.value.funding_count as number),
        },
        {
            label: i18n.t('asset_funding_total'),
            value: formatNumericValues(asset.value.funding_total as number),
        },
        {
            label: i18n.t('asset_investment_min'),
            value: formatNumericValues(asset.value.investment_min as number),
        },
        {
            label: i18n.t('asset_investment_max'),
            value: formatNumericValues(asset.value.investment_max as number),
        },
        {
            label: i18n.t('asset_step_level'),
            value: asset.value.step_level,
        },
        {
            label: i18n.t('asset_minimum_initial_payment'),
            value: formatNumericValues(
                asset.value.minimum_initial_payment as number
            ),
            condition: asset.value.minimum_initial_payment !== null,
        },
        {
            label: i18n.t('asset_maximum_initial_payment'),
            value: formatNumericValues(
                asset.value.maximum_initial_payment as number
            ),
            condition: asset.value.maximum_initial_payment !== null,
        },
        {
            label: i18n.t('asset_initial_payment_step'),
            value: asset.value.initial_payment_step,
            condition: asset.value.initial_payment_step !== null,
        },
        {
            label: i18n.t('asset_manual_funding_totals'),
            value: asset.value.manual_funding_totals,
            special: 'check',
        },
        {
            label: i18n.t('asset_deactivate_payment_account'),
            value: asset.value.deactivate_payment_account,
            special: 'check',
        },
        {
            label: i18n.t('asset_installment_investment'),
            value: asset.value.installment_investment,
            special: 'check',
        },
        {
            label: i18n.t('asset_installment_payout'),
            value: asset.value.installment_payout,
            special: 'check',
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_minimum_installment'),
            value: formatNumericValues(
                asset.value.minimum_installment as number
            ),
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_maximum_installment'),
            value: formatNumericValues(
                asset.value.maximum_installment as number
            ),
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_installment_step'),
            value: asset.value.installment_step,
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_minimum_monthly_rates'),
            value: asset.value.minimum_monthly_rates,
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_maximum_monthly_rates'),
            value: asset.value.maximum_monthly_rates,
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_minimum_yearly_rates'),
            value: asset.value.minimum_yearly_rates,
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('asset_maximum_yearly_rates'),
            value: asset.value.maximum_yearly_rates,
            condition: !!asset.value.installment_investment,
        },
        {
            label: i18n.t('require_qualified_signature'),
            value: asset.value.require_qualified_signature,
            special: 'check',
        },
        {
            label: i18n.t('signature_type'),
            value: asset.value.signature_sproof
                ? i18n.t('signature_type_sproof')
                : asset.value.signature_manual
                ? i18n.t('signature_type_manual')
                : i18n.t('none'),
        },
        {
            label: i18n.t('contract_period'),
            value: asset.value.contract_period,
            condition: asset.value.contract_period !== null,
        },
        {
            label: i18n.t('tags'),
            value: asset.value.tags,
            copy: true,
            condition: asset.value.tags !== null,
        },
        {
            label: i18n.t('created_at'),
            value: moment(asset.value.created_on).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(asset.value.updated_at).format('DD.MM.yyyy HH:mm'),
        },
    ]
})

const { Summary: AssetSummary } = useSummary(assetDetailsItems, {
    title: 'summary',
})

const {
    AssetDocumentsTableNew: ContractDocumentsTable,
    selectedAssetDocumentsIds: selectedContractDocuments,
    handleUpdateOrCreateAssetDocument:
        handleUpdateOrCreateAssetContractDocument,
    handleDeleteAssetDocuments: handleDeleteAssetContractDocuments,
} = useAssetDocumentsTable(
    contractDocuments,
    AssetDocumentType['CONTRACT_DATA'],
    update,
    openModal,
    isModalOpen
)

const {
    AssetDocumentsTableNew: ReportingDocumentsTable,
    selectedAssetDocumentsIds: selectedReportingDocuments,
    handleUpdateOrCreateAssetDocument:
        handleUpdateOrCreateAssetReportingDocument,
    handleDeleteAssetDocuments: handleDeleteAssetReportingDocuments,
} = useAssetDocumentsTable(
    reportingDocuments,
    AssetDocumentType['REPORTING'],
    update,
    openModal,
    isModalOpen
)

const {
    AssetDocumentsTableNew: ConsumerInformationDocumentsTable,
    selectedAssetDocumentsIds: selectedConsumerInformationDocuments,
    handleUpdateOrCreateAssetDocument:
        handleUpdateOrCreateAssetConsumerInformationDocument,
    handleDeleteAssetDocuments: handleDeleteAssetConsumerInformationDocuments,
} = useAssetDocumentsTable(
    consumerInformationDocuments,
    AssetDocumentType['CONSUMER_INFORMATION'],
    update,
    openModal,
    isModalOpen
)

function toAssets(): void {
    router.push({ name: 'assets' })
}

function editAsset(): void {
    router.push({ name: 'asset.edit', params: { id: asset.value.asset_uid } })
}

function openDeleteModal(): void {
    deleteModalOpen.value = true
}

async function deleteAsset() {
    if (!asset.value?.asset_uid) return
    await apiDeleteAsset(asset.value?.asset_uid)
    toast.success(
        i18n.t('toast_success_asset_deleted', { asset: asset.value?.asset_uid })
    )
    router.push({ name: 'assets' })
}

async function load() {
    if (route.params.id) {
        const assetRequest = await getAsset(route.params.id as string)
        asset.value = assetRequest.data.asset
        contract.value = asset.value.contract as Contract
        issuer.value = asset.value.issuer as Issuer
        voucherContract.value = asset.value.voucher_contract as Contract
        contracts.value = asset.value.contracts

        contractDocuments.value = asset.value.asset_documents.filter(
            (assetDocument) =>
                assetDocument.type === AssetDocumentType['CONTRACT_DATA']
        )
        reportingDocuments.value = asset.value.asset_documents.filter(
            (assetDocument) =>
                assetDocument.type === AssetDocumentType['REPORTING']
        )
        consumerInformationDocuments.value = asset.value.asset_documents.filter(
            (assetDocument) =>
                assetDocument.type === AssetDocumentType['CONSUMER_INFORMATION']
        )
        if (asset.value.payment_methods === null) {
            asset.value.payment_methods = new PaymentMethods()
        }

        const platformRequest = await getPlatform(
            asset.value.platform_name as string
        )
        platform.value = platformRequest.data.platform
    }
}

async function update(successMessage: string | null = null) {
    asset.value.asset_documents = [
        ...contractDocuments.value,
        ...reportingDocuments.value,
        ...consumerInformationDocuments.value,
    ]
    await updateAsset(asset.value)
    if (successMessage) {
        toast.success(successMessage)
    }
    await load()
}

async function exportAssetInvestmentReport() {
    await apiExportAssetInvestmentReport(asset.value)
        .catch((error) => {
            throw error
        })
        .then(() => {
            toast.success(i18n.t('toast_success_export_csv'))
        })
}

async function exportCheckOrdersReport() {
    await apiExportCheckOrdersReport(asset.value)
        .catch((error) => {
            throw error
        })
        .then((res) => {
            if (res.data.tasks) {
                toast.success(i18n.t('toast_success_export_csv'))
            } else {
                toast.success(res.data.message)
            }
        })
}

async function sendAdHocEmailReminder() {
    await apiSendAdHocEmailReminder(asset.value)
    toast.success(i18n.t('toast_success_ad_hoc_email_sent'))
}

function handleUploadNyalaRegistryExtract() {
    isModalOpen.value = true
    const nyalaRegistryExtractFile = ref<UploadFileResponse | null>(null)

    const disableCreate = computed(() => {
        return !nyalaRegistryExtractFile.value
    })

    openModal(
        {
            id: 'upload_nyala_registry_extract',
            title: 'upload_nyala_registry_extract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () =>
                nyalaRegistryExtractFile.value &&
                uploadNyalaRegistryExtract(nyalaRegistryExtractFile.value),
        },
        () =>
            h(UploadNyalaRegistryExtract, {
                modelValue: nyalaRegistryExtractFile,
            }),
        {
            submitButtonText: 'upload',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
            submitButtonDisabled: disableCreate,
        }
    )
}

async function handleRetrieveNyalaRegistryExtract() {
    const response = await retrieveNyalaRegistryExtract(asset.value)
    if (response.data.success) {
        toast.success(i18n.t('toast_success_retrieve_nyala_registry_extract'))
    }
}

async function uploadNyalaRegistryExtract(
    nyalaRegistryExtractFile: UploadFileResponse
) {
    isModalOpen.value = false

    const response = await apiUploadNyalaRegistryExtract(
        asset.value,
        nyalaRegistryExtractFile.key
    )

    if (response.data.success === true) {
        const registryExtract =
            response.data.registry_extract_data.registry_extract
        toast.success(
            i18n.t('toast_success_upload_nyala_registry_extract', {
                externalCustomerIds: registryExtract
                    .map((item) => item.external_customer_id)
                    .join(', '),
            })
        )
    }
}

async function handleSendNyalaRegistryDocumentMail() {
    const response = await sendNyalaRegistryDocumentMail(asset.value)
    if (response.data.success) {
        toast.success(
            i18n.t('toast_success_send_nyala_registry_document_email')
        )
    }
}

function openTransferToProjectWallet() {
    transferToProjectWalletModalOpen.value = true
}

async function transferToProjectWallet(data: { wallet: string; date: string }) {
    const response = await apiTransferToProjectWallet(
        asset.value,
        data.wallet,
        data.date
    )
    toast.success(
        i18n.t('toast_success_transfer_to_project_wallet', {
            log: response.data.log,
        })
    )
    transferToProjectWalletModalOpen.value = false
}

watch(
    () => route.params.id,
    async () => {
        await load()
    }
)

onMounted(async () => {
    load()
})
</script>

<style scoped lang="sass">
.details
    @apply flex flex-col gap-4 grow
    .detail
        .label
            @apply font-bold mr-2
            &:after
                content: ':'
        .value
            a
                @apply text-blue-500
</style>
